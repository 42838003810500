<template>
    <transition name="">
        <b-modal ref="offerModalRef" centered hide-header hide-footer class="modal fade">
          <div class="modal-center">
            <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;"><font-awesome-icon :icon="['fas', 'times']" /></a>
            <div class="text-center">
              <a href='#' ref='imgLink'><img :src="getImgPath()" @click="addGTMInfo"></a>
            </div>
          </div>
        </b-modal>
    </transition>
</template>

<script>

export default {
  name: 'OfferModal',

  methods: {
    addGTMInfo() {
        this.pushGTMOffer('promotionClick', 'VERANITO21', 'promoClick', 'VERANITO21', 'banner1', this.$refs.imgLink.href)
    },
    showModal() {
      this.$refs.offerModalRef.show()
      this.pushGTMOffer('promoView', 'VERANITO21', 'promoView','VERANITO21', 'banner1')
    },
    hideModal() {
      this.pushGTM('funnel', 'cancelar', 'recurrente')
      this.$refs.offerModalRef.hide()
    },
    getImgPath() {
      if (screen.width <= 760) {
        return require('@/assets/offer-modal/'+ 'veranito_movil.jpg')
      } else {
        return require('@/assets/offer-modal/'+ 'veranito_desktop.jpg')
      }
    }
  }
}
</script>

<style lang="scss">

</style>