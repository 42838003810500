<template>
    <transition name="">
        <b-modal ref="infoPSD2Ref" centered hide-header hide-footer class="modal fade">
          <div class="modal-center">
            <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;"><font-awesome-icon :icon="['fas', 'times']" /></a>
            <div class="text-center">
                <a class="text-right pr-4" style="font-size:30px;"></a> 
                <div class="col-12" v-html="$t('infoPSD2Modal.text')"></div>
            </div>
          </div>
        </b-modal>
    </transition>
</template>

<script>

export default {
  name: 'InfoPSD2Modal',
  methods: {
    showModal() {
      this.$refs.infoPSD2Ref.show()
    },
    hideModal() {
      this.pushGTM('funnel', 'cancelar', 'recurrente')
      this.$refs.infoPSD2Ref.hide()
    }
  }
}
</script>

<style lang="scss">

</style>