<template>
    <div class="container-fluid" :class="[`${currentLanding}`]">
        <CallRequestModal 
                ref="callRequestRef" 
                :phoneContact= "contactInfo.telephone"
                showQueryBox= false
                showHelpBox= false />
        <div v-if="!distributorLogo" class="row">
            <div class="col-12">
                <div id="progressTopBar" :style="`--width: ${progressWidth}%`"></div>
            </div>
        </div>
        <section>
            <div class="container wrapper-menu">
                <div class="row wrapper-menu-contract">
                    <div class="container-wrapper-menu-logo" :class="{'logos-container' : distributorLogo}">
                        <div class="wrapper-menu-logo">
                            <a :href="getHRef()">
                                <img :src="logo">
                            </a>
                        </div>
                        <div v-if="distributorLogo" class="wrapper-menu-logo logo-distributor">
                            <a :href="getHRef()">
                                <img :src="distributorLogo">
                            </a>
                        </div>
                    </div>
                    <div class="wrapper-menu-nav">
                        <div id="menu">
                            <a v-if="contactInfo.whatsappIsEnabled && !isHoliday()" class="whatsapp" tabindex="0" title="Whatsapp" :href="`https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}`">
                                <i><font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2" /></i><span data-nosnippet="">WhatsApp</span>
                            </a>
                            <a class="phone" :href="`tel:${contactInfo.telephone}`">
                                <i><font-awesome-icon icon="phone-alt" class="mr-2"/></i><span>{{ contactInfo.telephone }}</span>
                            </a>
                            <button type="button" class="btn-orange" @click="showCallRequestModal">{{ $t('headerOrderComponent.we_call_you') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br><br>
     </div>
</template>

<script>
import CallRequestModal from '@/components/modals/CallRequestModal.vue'
import navLink from '@/config/constants/navLink.js'
import landing from '@/config/constants/landing.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
    name: "HeaderOrderComponent",
    props: {
        currentLanding: {
            type: String
        },
        pageNumber: {
            type: Number
        },
        distributorLogo: {
            type: String
        }
    },
    components: { 
        CallRequestModal
    },
    data(){
        return{
            contactInfo
        }
    },
    methods: {
        showCallRequestModal(){
            this.pushGTM('funnel', 'te_llamamos', 'recurrente', '', '')
            this.$refs.callRequestRef.showModal()
        },
        getHRef(){
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                    return navLink.seguromovil
                case landing.seguropatinete.name:
                    return navLink.seguropatinete
                case landing.seguromultidispositivo.name:
                    return navLink.seguromultidispositivo
                case landing.seguroportatil.name:
                    return navLink.seguroportatil
                case landing.extensiondegarantia.name:
                    return navLink.extensiondegarantia
                default:
                    return ""
            }
        }
    },
    computed: {
        progressWidth(){
            if(this.pageNumber == 1){
                return 35
            }else{
                return 80
            }
        },
        logo() {
            try {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.svg`)
            } catch (e) {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.png`)
            }
        }
    }
}
</script>

<style lang="scss">
.container-wrapper-menu-logo{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.wrapper-menu-contract{
    justify-content: space-between;
    flex-wrap: nowrap;
}

.wrapper-menu-logo{
    max-width: 230px;
    height: auto;
    padding: 5px 0;
}
.wrapper-menu-logo.logo-distributor {
    max-width: 160px;
}
.wrapper-menu-logo img{
    width: 100%;
    height: auto;
}
.wrapper-menu-nav{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

#menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

#menu a{
    text-decoration: none;
    padding-right: 20px;
    font-size: 15px;
    font-weight: 600;
}
@media(max-width: 360px) {
    #menu a{
        padding-right: 5px;
    }
}

#menu .whatsapp {
    color: $whatsapp-color;
}

#menu .phone {
    color: $blue-color;
}

#menu .phone:hover {
    color: $orange-color;
}

@media(max-width: 699.98px) {
    #menu a span{
        display: none;
    }
    #menu .btn-orange {
        font-size: calc(8px + 1vw);
        padding: 5px 8px;
    }
    .menu-logo {
        max-height: calc(26px + 2vw);
    }
}

#progressTopBar {
    background-color: $orange-color;
    --width: 15%;
    width: var(--width);
    height: 8px;
    -webkit-transition: all 2s ease-out;
    -moz-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
}

@media only screen and (max-width: 768px) {

    .wrapper-menu{
        padding: 0;
    }

    .wrapper-menu-contract{
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap:nowrap;
        overflow-x: hidden;
        margin:0;
    }

    .wrapper-menu-nav{
        padding: 0;
        flex-wrap: nowrap;
        flex: 1 1 0;
        min-width: 0;
    }

    #menu{
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
    .logos-container {  
        margin-right: 1rem;
        .wrapper-menu-logo{
            max-width: 140px;
            height: auto;
            padding: 5px 0;
        }
        .wrapper-menu-logo.logo-distributor {
            max-width: 80px;
        }
    }
}
@media only screen and (max-width: 449px) {
    .logos-container{
        flex-direction: column;
        gap: 0rem;
    }
}
</style>
