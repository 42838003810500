<template>
    <footer>
        <div class="nectar-shape-divider-wrap-footer">
            <svg class="nectar-shape-divider-footer" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">  
                <path d="M 1014 264 v 122 h -808 l -172 -86 s 310.42 -22.84 402 -79 c 106 -65 154 -61 268 -12 c 107 46 195.11 5.94 275 137 z"></path>   <path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>  
                <path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>  
                <path d="M -24 69 s 299.68 301.66 413 245 c 8 -4 233 2 284 42 c 17.47 13.7 172 -132 217 -174 c 54.8 -51.15 128 -90 188 -39 c 76.12 64.7 118 99 118 99 l -12 132 l -1212 12 z"></path>  
                <path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
            </svg>
        </div>
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-6">
                    <h2 v-html="$t('homeFooterComponent.big_ideas')"></h2>
                </div>
                <div class="col-lg-6 d-flex justify-content-end">
                    <a :href="`mailto:${contactInfo.email}`" class="btn btn-primary"><span class="line" style="background-color: #ffffff;"></span> {{ $t('homeFooterComponent.tell_us') }}</a>
                </div>
            </div>
        </div>
        <div class="copyright d-flex flex-column align-items-center">
            <p>
                <router-link :to="{ name: 'legal-warning'}" target="_blank">{{ $t('homeFooterComponent.legal_warning') }}</router-link> /
                <router-link :to="{ name: 'privacity'}" target="_blank">{{ $t('homeFooterComponent.privacy_policy') }}</router-link> /
                <router-link v-if="!isMxBroker()" :to="{ name: 'cookies'}" target="_blank">{{ $t('homeFooterComponent.cookies_policy') }}</router-link> <span v-if="!isMxBroker()">/</span>
                <a href="https://nervogroup.com/codigo-de-etico-del-nervo-group" target="_blank" rel="noopener noreferrer">{{ $t('homeFooterComponent.ethical_code') }}</a>
            </p>
            <p>{{ $t('homeFooterComponent.copyright') }}</p>
            <ul class="social d-flex">
                <li><a target="_blank" href="https://www.linkedin.com/company/insurama/"><i><font-awesome-icon :icon="['fab', 'linkedin']"/></i></a></li>
                <li><a target="_blank" :href="`mailto:${contactInfo.email}`"><i><font-awesome-icon icon="envelope" /></i></a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
    import contactInfo from '@/config/constants/contactInfo.js'
    export default {
        name: "HomeFooterComponent",
        data: function () {
            return {
                contactInfo
            }
        },
        methods: {
            isMxBroker() {
                return this.$i18n.locale == 'mx'
            },
        },
    }
</script>
<style lang="scss">

</style>