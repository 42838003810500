<template>
    <section>
        <div class="form-group" v-if="!isMxBroker()">
            <div class="row " >
                <div class="col-xs-12 col-md-6 mt-4">
                    <label for="id_number">{{$t('order.userInputs.nif')}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" :class="isValidNifNieClass(userData.nif, step2BtnClicked)" autocomplete="nope" v-model="userData.nif" v-on:blur="isValidNifNie(userData.nif) ? pushGTM('funnel', 'nif', 'paso2', '', policyData.product.name) : ''">
                        <div class="input-group-append">
                            <div class="input-group-text" :class="isValidNifNieClass(userData.nif, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'id-card']"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="name">{{$t('order.userInputs.name_lastname')}}</label>
            <div class="input-group">
                <input type="text" class="form-control" :class="isValidTextClass(userData.name, 4, step2BtnClicked)" required :placeholder="$t('order.userInputs.name_lastname')" autocomplete="nope" v-model="userData.name" v-on:blur="isValidTextField(userData.name, 4) ? pushGTM('funnel', 'nombre', 'paso2', '', policyData.product.name) : ''">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidTextClass(userData.name, 4, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="email">{{$t('order.userInputs.email')}}</label>
            <div class="input-group">
                <input type="text" class="form-control" :class="isValidEmailClass(userData.email, step2BtnClicked)" :placeholder="$t('order.userInputs.email_placeholder')" autocomplete="nope" v-model="userData.email" v-on:blur="pushGTMEmailInfo()">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidEmailClass(userData.email, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'at']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="birth-date">{{$t('order.userInputs.birth_date')}}</label>
            <div class="input-group">
                <b-form-datepicker :class="isValidBirthDateClass(userData.birth_date, step2BtnClicked)" id="example-datepicker" placeholder="dd/mm/aaaa" :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="max" show-decade-nav hide-header  locale="es" @input="updateBirthDate($event)"></b-form-datepicker>
            </div>
        </div>
         <div class="form-group" v-if="isMxBroker()">
            <div class="row" >
                <div class="col-xs-12 col-md-6 mt-4">
                    <label for="nif">{{$t('order.userInputs.nif')}}</label>
                    <div class="input-group">
                        <input type="text" id="nif" ref="nif" :disabled="userData.birth_date.length == 0 || !isValidBirthDate(userData.birth_date)" class="form-control" :class="isValidNifNieClass(userData.nif, step2BtnClicked, userData.birth_date)" autocomplete="nope" v-model="userData.nif" v-on:blur="isValidNifNie(userData.nif, userData.birth_date) ? pushGTM('funnel', 'nif', 'paso2', '', policyData.product.name) : ''" @focus="setNifAutocompleted()">
                        <div class="input-group-append">
                            <div class="input-group-text" :class="isValidNifNieClass(userData.nif, step2BtnClicked, userData.birth_date)"><font-awesome-icon :icon="['fas', 'id-card']"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomContactInput
            :currentLanding="currentLanding"
            :policyData="policyData"
        />
        <div class="form-group" v-if="isMxBroker()">
            <label for="postal-code">{{$t('order.userInputs.postal_code')}}</label>
            <div class="input-group">
                <input type="number" id="postal-code" class="form-control" :class="isValidNumberClass(userData.postal_code, 4, step2BtnClicked)" required :placeholder="$t('order.userInputs.postal_code')" autocomplete="nope" v-model="userData.postal_code">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidNumberClass(userData.postal_code, 4, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="state">{{$t('order.userInputs.state')}}</label>
            <div class="input-group">
                <input type="text" id="state" class="form-control" :class="isValidTextClass(userData.state, 3, step2BtnClicked)" required :placeholder="$t('order.userInputs.state')" autocomplete="nope" v-model="userData.state">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidTextClass(userData.state, 3, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="town">{{$t('order.userInputs.town')}}</label>
            <div class="input-group">
                <input type="text" id="town" class="form-control" :class="isValidTextClass(userData.town, 3, step2BtnClicked)" required :placeholder="$t('order.userInputs.town')" autocomplete="nope" v-model="userData.town">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidTextClass(userData.town, 3, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="colony">{{$t('order.userInputs.colony')}}</label>
            <div class="input-group">
                <input type="text" id="colony" class="form-control" :class="isValidTextClass(userData.colony, 3, step2BtnClicked)" required :placeholder="$t('order.userInputs.colony')" autocomplete="nope" v-model="userData.colony">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidTextClass(userData.colony, 3, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="street">{{$t('order.userInputs.street')}}</label>
            <div class="input-group">
                <input type="text" id="street" class="form-control" :class="isValidTextClass(userData.street, 3, step2BtnClicked)" required :placeholder="$t('order.userInputs.street')" autocomplete="nope" v-model="userData.street">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidTextClass(userData.street, 3, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="exterior-number">{{$t('order.userInputs.exterior_number')}}</label>
            <div class="input-group">
                <input type="number" id="exterior-number" class="form-control" :class="isValidNumberClass(userData.exterior_number, 1, step2BtnClicked)" required :placeholder="$t('order.userInputs.exterior_number')" autocomplete="nope" v-model="userData.exterior_number">
                <div class="input-group-append">
                    <div class="input-group-text" :class="isValidNumberClass(userData.exterior_number, 1, step2BtnClicked)"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div class="form-group" v-if="isMxBroker()">
            <label for="inner-number">{{$t('order.userInputs.interior_number')}}</label>
            <div class="input-group">
                <input type="text" id="interior-number" class="form-control" :placeholder="$t('order.userInputs.interior_number')" autocomplete="nope" v-model="userData.inner_number">
                <div class="input-group-append">
                    <div class="input-group-text"><font-awesome-icon :icon="['fas', 'keyboard']"/></div>
                </div>
            </div>
        </div>
        <div id="capa-tarjeta">
            <!--<div v-if="validCard.ibanRequired">
                <div class="form-group">
                    <label>{{$t('order.userInputs.iban')}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" :class="isValidIBANClass(userData.iban, step2BtnClicked)" placeholder="ESXX 0000 0000 0000 0000 0000" v-model="userData.iban" v-on:blur="pushCardGTM()">
                        <div class="input-group-append">
                            <div class="input-group-text"><font-awesome-icon icon="money-check-alt"/></div>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="form-group">
                <label class="mb-0">{{ $t('order.payment.title') }}</label>
                <p class="color-light">{{ $t('order.payment.description') }}</p>
            </div>
            <div class="form-group">
                <label>{{ $t('order.bottomInputs.discount') }}</label>
                <input disabled :value="this.code" class="form-control mb-3"/>
            </div>
            <div class="custom-control custom-checkbox mb-2">
                <input type="checkbox" id="acceptance_type1" name="acceptance_type" class="custom-control-input" @click="acceptTerms(1)" :checked="acceptance_type == 1">
                <label class="custom-control-label privacity" :class="isValidAcceptanceTypeClass(userData.acceptance_type, step2BtnClicked)" for="acceptance_type1" v-html="$t('order.userInputs.terms_1', { landing: this.currentLanding })"></label>
            </div>
            <div class="custom-control custom-checkbox mb-4" v-if="!isMxBroker()">
                <input type="checkbox" id="acceptance_type2" name="acceptance_type" class="custom-control-input" @click="acceptTerms(2)" :checked="acceptance_type == 2">
                <label class="custom-control-label privacity" :class="isValidAcceptanceTypeClass(userData.acceptance_type, step2BtnClicked)" for="acceptance_type2" v-html="$t('order.userInputs.terms_2', { landing: this.currentLanding })"></label>
            </div>
            <div class="custom-control custom-checkbox mb-4" v-if="isMxBroker()">
                <input type="checkbox" id="acceptance_type2" name="acceptance_type" class="custom-control-input" @click="acceptTerms(2)" :checked="acceptance_type_2 == 2">
                <label class="custom-control-label privacity" :class="isValidAcceptanceTypeClass(userData.acceptance_type_2, step2BtnClicked)" for="acceptance_type2" v-html="$t('order.userInputs.terms_2', { landing: this.currentLanding })"></label>
            </div>
            <div class="custom-control custom-checkbox mb-4" v-if="!isMxBroker()">
                <input type="checkbox" id="acceptance_coverages" name="acceptance_coverages" class="custom-control-input" @click="acceptCoverages(1)" :checked="acceptance_coverages == 1">
                <label class="custom-control-label privacity" :class="isValidAcceptanceTypeClass(userData.acceptance_coverages, step2BtnClicked)" for="acceptance_coverages" v-html="$t('order.userInputs.terms_coverages', { link: coverageLink })"></label>
            </div>
      </div>
      <InfoPSD2Modal
            ref="infoPSD2Ref" />
    </section>
</template>

<script>
import InfoPSD2Modal from '@/components/modals/InfoPSD2Modal.vue'
import dayjs from 'dayjs';

import BottomContactInput from '@/components/order/step1/elements/BottomContactInput.vue';


export default {
    name: "UserInputsDiscount",
    components: {
        InfoPSD2Modal,
        BottomContactInput
    },
    props: {
        code: {
            type: String
        },
        currentLanding: {
            type: String
        },
        userData: {
            type: Object,
        },
        policyData: {
            type: Object,
        },
        step2BtnClicked: {
            type: Boolean
        }
    },
    data() {
        return {
            validCard: {
                valid: false,
                message: "",
                ibanRequired: false
            },
            acceptance_type: 0,
            acceptance_type_2: 0,
            acceptance_coverages: 0,
            max: dayjs().format('YYYY-MM-DD'),
        }
    },
    watch: {
        userData() {
            this.$emit('input', this.userData);
        }
    },
    methods: {
        validateCard(){
            if(this.userData.card_expiration_month.length > 0 && this.userData.card_expiration_year.length > 0){
                this.validCard = this.isValidCard(this.userData)
                this.pushCardGTM()
            }
        },
        pushGTMEmailInfo() {
            if(this.isValidEmail(this.userData.email)) {
                this.pushGTM('funnel', 'email', 'paso2', '', this.policyData.product.name);
                this.pushGTMEmail(this.userData.email)
            }
        },
        setNifAutocompleted() {
            if (this.isMxBroker() && this.userData.nif.length == 0) {
                if (this.userData.name.length > 0) {
                    let arrayName = this.userData.name.split(' ');
                    let secondlastname = arrayName[arrayName.length - 1]
                    let firstlastname = arrayName[arrayName.length - 2]
                    let name = arrayName[0]

                    let startLettersSecondlastname = secondlastname.substring(0,1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
                    let startLettersFirstlastname = firstlastname.substring(0,2).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
                    let startLettersname = name.substring(0,1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

                    this.userData.nif = startLettersFirstlastname + startLettersSecondlastname + startLettersname
                } else {
                    this.userData.nif = 'XXXX'
                }

                if (this.userData.birth_date.length > 0) {
                    const year = this.userData.birth_date.substring(8, 10)
                    const month = this.userData.birth_date.substring(3, 5)
                    const day = this.userData.birth_date.substring(0, 2)
                    const number = year+month+day
                    this.userData.nif += number
                } else {
                    this.userData.nif += 'XXXXXX'
                }

                this.userData.nif +='XXX'
            }
        },
        acceptTerms(value){
            if (!this.isMxBroker()) {
                if (this.acceptance_type == value){
                    this.userData.acceptance_type = 0
                    this.acceptance_type = 0
                }else{
                    this.pushGTM('funnel', 'terminos', 'paso2', value, this.policyData.product.name)
                    this.acceptance_type = value
                    this.userData.acceptance_type = value
                }
            } else {
                /* terms 1*/

                if (value == 1 ) {
                    if (this.acceptance_type != value) {
                        this.pushGTM('funnel', 'terminos_1', 'paso2', value, this.policyData.product.name)
                        this.acceptance_type = value
                        this.userData.acceptance_type = true
                    } else {
                        this.userData.acceptance_type = 0
                        this.acceptance_type = 0
                    }

                }

                /* terms 2*/
                if (value == 2) {
                    if (this.acceptance_type_2 != value) {
                        this.pushGTM('funnel', 'terminos_2', 'paso2', value, this.policyData.product.name)
                        this.acceptance_type_2 = value
                        this.userData.acceptance_type_2 = true
                    } else {
                        this.userData.acceptance_type_2 = 0
                        this.acceptance_type_2 = 0
                    }

                }
            }

        },
        acceptCoverages(value){
            if(this.acceptance_coverages == value){
                this.userData.acceptance_coverages = 0
                this.acceptance_coverages = 0
            }else{
                this.acceptance_coverages = value
                this.userData.acceptance_coverages = value
            }
        },
        showInfoPSD2Modal(){
            this.$refs.infoPSD2Ref.showModal()
        },
        pushCardGTM(){
            if((this.isValidCardNumber(this.userData.card_number) && this.isValidCard(this.userData).valid && this.isValidCVVCard(this.userData.card_cvv)) || this.isValidIBAN(this.userData.iban)){
                this.pushGTM('funnel', 'tarjeta', 'paso2', '', this.policyData.product.name)
            }
        },
        isMxBroker() {
           return this.$i18n.locale == 'mx'
        },
        updateBirthDate(date){
            this.userData.birth_date = dayjs(date).format('DD/MM/YYYY');
            if(this.isValidBirthDate(this.userData.birth_date)) this.pushGTM('funnel', 'fecha_nacimiento', 'paso2', '', this.policyData.product.name);
        }
    },
    computed: {
        coverageLink() {
            switch (this.policyData.product.product_id) {
                case 1:
                case 5:
                case 15:
                case 18:
                    return '/assets/pdf/todo_danni.pdf'
                default:
                    return '/assets/pdf/todo_multi.pdf'
            }
        }
    }
}

</script>

<style lang="scss">

.form-group label {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.form-group p.color-light {
    color: #495371;

}

#explainExpiration {
    font-size: 0.8rem
}

.privacity {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 0.7rem;
    color: $blue-color-light;
    font-weight: 100;
    text-transform: none;
}

.privacity a{
    color: $blue-color-light;
    font-weight: bold;
}

#nif.valid:disabled, #nif.notValid:disabled, #nif.valid:disabled + div .input-group-text.valid, #nif.notValid:disabled + div .input-group-text.notValid{
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
}
</style>
