<template>
    <section class="form_phone_discount">
        <div class="form-group mt-4">
            <label for="phone_number">{{$t('order.bottomInputs.phone')}}</label>
            <div class="input-group">
                <input class= 'form-control' @blur="sendLostCartAnalytic" :class="isValidPhoneClass(policyData.phone, step1BtnClicked)" v-model="policyData.phone" :placeholder="$t('callRequestModal.write_your_phone')" autocomplete="nope" v-on:blur="isValidPhone(policyData.phone) ? pushGTM('funnel', 'telefono', 'paso1', '', policyData.product.name) : ''"/>
                <div class="input-group-append ">
                    <div class="input-group-text" :class="isValidPhoneClass(policyData.phone, step1BtnClicked)">
                        <font-awesome-icon icon="phone-alt"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="discount_code">{{$t('order.bottomInputs.discount')}}</label>    
            <div class="input-group">
                <input type="text" class="form-control" v-model="discountCode" placeholder="123456XYZ">        
                <div class="input-group-append">            
                    <button type="button" class="btn-blue"  @click="applyCode()">{{$t('order.bottomInputs.apply')}}</button>        
                </div>
            </div>
        </div>
    </section>
</template>

<script>

/* API-REST */
import { getGenericItemParams } from '@/api/ApiClientParamsSetter.js'
import { createOrUpdateGenericItem } from '@/api/ApiClient.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
    name: "BottomInputs",
    props: {
        currentLanding: {
            type: String
        },
        policyData: {},
        step1BtnClicked: {
            type: Boolean
        }
    },
    data() {
        return {
            discountCode: "",
            contactInfo
        }
    },
    methods: {
        applyCode(){
            this.pushGTM('funnel', 'descuento', 'paso1', this.discountCode, this.policyData.product.name, true)
            this.$emit('discountCodeApplied', this.discountCode)
        },
        async sendLostCartAnalytic() {
            /* Carrito abandonado */
            if (this.isValidPhone(this.policyData.phone)) {
                const id = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || 'GA';

                if (!this.policyData.phone.trim().startsWith("+")) {
                    var phone = contactInfo.prefix + this.policyData.phone
                }

                var params = getGenericItemParams(this.currentLanding, id, {"phone": phone}, "lost_cart");
                var result = await createOrUpdateGenericItem(params); 
                console.log(result);
            }
        }
    }
}

</script>

<style lang="scss">

.form_phone_discount .form-group label{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase; 
}

.input-group-append button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px 15px;
}

</style>
