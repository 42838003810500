<template>
    <div class="container main-box">
        <div class="col-12">
            <div class="row section-content">
                <div class="col-2 content_1">
                    <a v-if="pageNumber == 2" @click="emitBackPressed"><font-awesome-icon :icon="['fas', 'angle-left']" class="back-button pointer"/></a> 
                </div>
                <div class="col-8 content_2">
                    <h2 class="section-title pageTitle">{{title}}</h2>
                </div>
                <div class="col-2 content_3">
                    <span class ="section-title">{{pageNumber}}</span>
                    <span>/2</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import landing from '@/config/constants/landing.js'

export default {
    name: 'OrderTitle',
    props: {
        currentLanding: {
            type: String
        },
        pageNumber: {
            type: Number
        }
    },
    methods: {
        emitBackPressed(){
            this.$emit('backPressed')
        }
    },
    computed: {
        title () {
            if(this.pageNumber == 2){
                return this.$t('order.orderTitle.title_step2')
            }
            else if(this.currentLanding == landing.seguromultidispositivo.name){
                return this.$t('order.orderTitle.title_multidevice_step1')
            }else{
                return this.$t('order.orderTitle.title_step1')
            }
        }
    }
}

</script>

<style lang="scss" scoped>

.container{
    max-width: 600px;
    margin: 0 auto;
    padding: 0;
}

.main-box .section-content{
    justify-content: space-between;
}

.main-box .section-content .content_1{
    
    text-align: left;
}
.main-box .section-content .content_2{
    padding: 0;
    text-align: center;
}
.main-box .section-content .content_3{
    
    text-align: right;
}
.main-box .section-title{
    font-weight: 500;
    font-size: 1.5rem;
}

@media (max-width: 700px){
   .main-box .pageTitle{
        font-size: 1.2rem;
    } 
}

.back-button {
    color: $orange-color;
    font-size: 25px;
    font-weight: 800;
    line-height: 20px;
    text-decoration: none;
}

.back-button:hover {
    color: $blue-color;
}

</style>