<template>
  <section class="form_phone_discount">
      <div class="form-group mt-4">
          <label for="phone_number">{{$t('order.bottomInputs.phone')}}</label>
          <div class="input-group">
              <input class= 'form-control' @blur="sendLostCartAnalytic" :class="isValidPhoneClassDiscount(policyData.phone, step1BtnClicked)" v-model="policyData.phone" :placeholder="$t('callRequestModal.write_your_phone')" autocomplete="nope" v-on:blur="isValidPhone(policyData.phone) ? pushGTM('funnel', 'telefono', 'paso1', '', policyData.product.name) : ''"/>
              <div class="input-group-append ">
                  <div class="input-group-text" :class="isValidPhoneClassDiscount(policyData.phone, step1BtnClicked)">
                      <font-awesome-icon icon="phone-alt"/>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>

/* API-REST */
import { getGenericItemParams } from '@/api/ApiClientParamsSetter.js'
import { createOrUpdateGenericItem } from '@/api/ApiClient.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
  name: "BottomInputs",
  props: {
      currentLanding: {
          type: String
      },
      policyData: {},
      step1BtnClicked: {
          type: Boolean
      }
  },
  data() {
      return {
          contactInfo
      }
  },
  methods: {
      async sendLostCartAnalytic() {
          /* Carrito abandonado */
          if (this.isValidPhone(this.policyData.phone)) {
              const id = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || 'GA';

              if (!this.policyData.phone.trim().startsWith("+")) {
                  var phone = contactInfo.prefix + this.policyData.phone
              }

              var params = getGenericItemParams(this.currentLanding, id, {"phone": phone}, "lost_cart");
              await createOrUpdateGenericItem(params);
          }
      }
  }
}

</script>

<style lang="scss">

.form_phone_discount .form-group label{
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.input-group-append button{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 15px;
}

</style>
