<template>
    <section class="prices" :class="[`tema--${currentLanding}`]">
        <div class="wrap_prices-card" >
            <div class="prices-card" v-for="(insurance, index ) in insurances" v-bind:key="insurance.name">
                <section class="pricing btn-card" :class="[`index--${index}`]">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm p-0">
                                <div class="card col-md-12" :data-url="[`#layer-${index}`]" :id="[`btn--${index}`]">
                                    <div v-if="currentLanding != landing.seguromultidispositivo.name" class="pricingHeader">
                                        <h5 class="card-title orange">{{insurance.name}}</h5>
                                        <img v-show="isLoading" src='@/assets/loader.gif' height=20 style='margin-right:10px;margin-top:5px;'>
                                        <h6 v-show="!isLoading" :ref="'product_id_' + insurance.product_id" class="card-subtitle"></h6>
                                    </div>
                                    <div v-else class="cabeceraPricing pricingHeader">
                                        <div v-html="multiDeviceText()"/>
                                        <div class="bold card-subtitle">
                                            <img loading="lazy" v-show="isLoading" src='@/assets/loader.gif' height=20 style='margin-right:10px;margin-top:5px;'>
                                            <h6 v-show="!isLoading" :ref="'product_id_' + insurance.product_id" class="card-subtitle"></h6>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <ul class="list-unstyled">
                                            <div v-for="(coverage, index) in insurance.coverages" v-bind:key="coverage.name">
                                                <li v-if="coverage.it_covers" class="bold"><font-awesome-icon :icon="['fas', 'check']" class="green"/><span v-html="nameText(coverage)"/></li>
                                                <li v-else><font-awesome-icon :icon="['fas', 'times']" class="red"/><span class="disabled" v-html="nameText(coverage)"/></li>
                                                <span class="card-body_text" v-if="currentLanding == landing.seguromultidispositivo.name && index == insurance.coverages.length - 1" style="font-size:0.6rem;line-height: 1.4em;">{{ $t('calculatePriceComponent.insuranceBoxes.information guarantees') }}</span>
                                            </div>
                                        </ul>
                                        <router-link v-if="isEnableOrderProcess" :to="getOrderLink(insurance)"
                                            @click.native="pushGTMData(insurance, index)"
                                            data-id = 1 class="btn btn-outline-danger mr-3 bold mt-1 btn-block"
                                            >{{ $t('calculatePriceComponent.insuranceBoxes.buy_now') }}</router-link>
                                        <p v-if="currentLanding != landing.seguromultidispositivo.name" v-html="$t('calculatePriceComponent.insuranceBoxes.without_franchises_or_shortages')"></p>
                                        <p v-else v-html="$t('calculatePriceComponent.insuranceBoxes.without_franchises_or_shortages2')"></p>
                                        <p v-if="currentLanding == landing.seguromultidispositivo.name" style="font-size:0.6rem">{{ $t('calculatePriceComponent.insuranceBoxes.information_points') }}</p>
                                        <p v-if="currentLanding == landing.seguromultidispositivo.name" style="font-size:0.6rem">{{ $t('calculatePriceComponent.insuranceBoxes.contracting_conditions') }}</p>
                                        <p class="p-conditions"><a class="conditions-box" v-if="isEnableOrderProcess" href="/assets/pdf/condiciones_producto.pdf" target="_blank">{{ $t('footerComponent.product_conditions') }}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="mobile_prices-card" >
            <div class="mobile-card" v-for="(insurance, index ) in insurances" v-bind:key="insurance.name">
                <section class="pricing layer-card" :id="[`layer-${index}`]">
                    <div class="container" >
                        <div class="row">
                            <div class="col-sm p-0">
                                <div class="mobile_card col-md-12">
                                    <div class="mobile_card-body">
                                        <ul class="list-unstyled">
                                            <div v-for="(coverage, index) in insurance.coverages" v-bind:key="coverage.name">
                                                <li v-if="coverage.it_covers" class="bold"><font-awesome-icon :icon="['fas', 'check']" class="green"/><span v-html="nameText(coverage)"/></li>
                                                <li v-else><font-awesome-icon :icon="['fas', 'times']" class="red"/><span class="disabled" v-html="nameText(coverage)"/></li>
                                                <span class="card-body_text" v-if="currentLanding == landing.seguromultidispositivo.name && index == insurance.coverages.length - 1" style="font-size:0.6rem;line-height: 1.4em;">{{ $t('calculatePriceComponent.insuranceBoxes.information guarantees') }}</span>
                                            </div>
                                        </ul>
                                        <router-link v-if="isEnableOrderProcess" :to="getOrderLink(insurance)" data-id = 1
                                            @click.native="pushGTMData(insurance, index)"
                                            class="btn btn-outline-danger mr-3 bold mt-1 btn-block"
                                        >{{ $t('calculatePriceComponent.insuranceBoxes.buy_now') }}</router-link>
                                        <p v-if="currentLanding != landing.seguromultidispositivo.name" v-html="$t('calculatePriceComponent.insuranceBoxes.without_franchises_or_shortages')"></p>
                                        <p v-else v-html="$t('calculatePriceComponent.insuranceBoxes.without_franchises_or_shortages2')"></p>
                                        <p v-if="currentLanding == landing.seguromultidispositivo.name" style="font-size:0.6rem">{{ $t('calculatePriceComponent.insuranceBoxes.information_points') }}</p>
                                        <p v-if="currentLanding == landing.seguromultidispositivo.name" style="font-size:0.6rem">{{ $t('calculatePriceComponent.insuranceBoxes.contracting_conditions') }}</p>
                                        <p class="p-conditions"><a class="conditions-box" v-if="isEnableOrderProcess" href="/assets/pdf/condiciones_producto.pdf" target="_blank">{{ $t('footerComponent.product_conditions') }}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div v-if="showBottomInfo() && isEnableOrderProcess" class="row" >
            <div class="col-lg-8 col-md-6 col-sm-12 mt-5 mb-5 box text-center">
                <img v-show="isLoading" src='@/assets/loader.gif' height=20 style='margin-right:10px;margin-top:5px;'>
                <a v-show="!isLoading" class="d-block" :href="navLink.seguromultidispositivo"><img src="@/assets/img/multidispositivo.svg" width="50"><span v-html="$t(`calculatePriceComponent.insuranceBoxes.bottom_info.${currentLanding}`, {price: lowerMultidevicePrice})"/></a>
            </div>
        </div>
    </section>
</template>

<script>
import { calculateInsurancesRates } from './js/calculate-insurances-rates.js'
import { getProductCategoryId } from '@/api/ApiClientUtils.js'
import landing from '@/config/constants/landing.js'
import navLink from '@/config/constants/navLink.js'
import { getters } from '@/state/store.js'
import SliderConfiguration from './config/slider.json'

export default {
    name: "InsuranceBoxes",
    props: {
        currentLanding: {
            type: String,
            required: true
        },
        sliderValue: {
            type: Number,
            required: true
        }
    },
    data(){
        return{
            insurances: this.getInsurances(),
             lowerMultidevicePrice: '',
            isLoading: false,
            navLink,
            landing,
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
            insurancesPricesIndexed: [],
            sliderValuemodified: false,
        }
    },
    watch: {
        sliderValue(){
            this.calculate()
        }
    },
    methods: {
        getInsurances(){
            var insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === this.currentLanding})[0]
            return insurancesFiltered.insurances
        },
        getOrderLink(insurance){
            var product_category_id = getProductCategoryId(this.sliderValue, insurance)
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                case landing.seguromultidispositivo.name:
                case landing.seguroportatil.name:
                case landing.extensiondegarantia.name:
                    return `/order?u=${this.currentLanding}&id=${this.getProductId()}&id_category=${product_category_id}&price=${this.sliderValue}`
                default:
                    return `/order?u=${this.currentLanding}&id=${this.getProductId()}&price=${this.sliderValue}`
            }
        },
        getProductId(){
            var product = getters.getProducts().filter((x) => this.currentLanding === landing.seguromultidispositivo.name ? x.product_type.identifier.split('tu')[1] === this.currentLanding 
            : x.product_type.identifier.split('tu')[1] === this.currentLanding && x.name.split(" ")[0].toLowerCase().includes('top') )
            return product[0].id;
        },
        async calculate(){
            if(this.sliderValue > 0){
                this.isLoading = true
                var insurancesPrices = await calculateInsurancesRates(this.sliderValue, this.currentLanding, this.insurances)
                insurancesPrices.forEach(insurance => {
                    this.isLoading = false
                    var productReference = "product_id_" + insurance.productId
                    const priceBox = Array.isArray(this.$refs[productReference]) ? this.$refs[productReference][0] : this.$refs[productReference]
                    if(insurance.error){
                        priceBox.innerHTML = this.$t('calculatePriceComponent.insuranceBoxes.price_not_available')
                    }else{
                        this.insurancesPricesIndexed[insurance.productId] = insurance.price
                        if(landing.extensiondegarantia.name == this.currentLanding){
                            priceBox.innerHTML = `<span>$ </span>${insurance.price}`
                        }else{
                            priceBox.innerHTML = `<span>$ </span>${insurance.price}<span> ${this.$t('calculatePriceComponent.insuranceBoxes.month_suffix')}</span>`
                        }
                    }
                })

                if (this.sliderValuemodified != false) {
                    this.pushGTMPriceData()
                }

                this.sliderValuemodified = true
            }
        },
        async getLowerPriceMultidevice(){
            let insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === landing.seguromultidispositivo.name})[0]
            let lowerRange = SliderConfiguration.filter((x) => {return x.landing === landing.seguromultidispositivo.name})[0].sliderConfig.arrayValues[0];
            var insurancesPrices = await calculateInsurancesRates(lowerRange, landing.seguromultidispositivo.name, insurancesFiltered.insurances)
            this.lowerMultidevicePrice = String(insurancesPrices[0].price);
        },
        pushGTMData(insurance, index) {
            this.pushGTMEcommerceClikData(insurance, index);
            this.pushGTM('funnel', 'continuar_paso0', 'paso0', '', insurance.name);
            let evetName = "";
             switch (this.currentLanding) {
                case landing.seguromovil.name:
                    evetName='coste_movil';
                    break;
                case landing.seguromultidispositivo.name:
                    evetName='coste_multidispositivo';
                    break;
                case landing.seguroportatil.name:
                    evetName='coste_portatil';
                    break;
                default:
                    evetName='';
            }

            this.pushGTM('funnel', evetName, 'paso0', '')
        },
        async layers() {
            /*tema--seguromovil*/
            if (document.querySelector('.tema--seguromovil')) {
                let cardActive = document.querySelector(".tema--seguromovil").querySelectorAll(".index--1 .card");
                cardActive.forEach(element => {
                    element.classList.add("active");
                });
                if (window.innerWidth >= 580) {
                    let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                    [].forEach.call(card, function(el) {
                        el.addEventListener("mouseover", function() {
                            card.forEach(hide=>hide.classList.remove("active"));
                        }, false);
                    });
                    card.forEach(el=> {
                        el.addEventListener("mouseover", function() {
                            el.classList.remove("active");
                        }, false);
                    });
                }
                /*mostrar capas*/
                let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                card.forEach(element => {
                    element.addEventListener("click", function(){
                        let dataUrl = element.dataset.url;
                        let layer = document.querySelector(dataUrl);
                        let hideLayer = document.querySelectorAll(".layer-card");

                        if (this.classList.contains("block")){
                            this.classList.remove("block");
                        } else{
                            hideLayer.forEach(hide=>hide.classList.remove("block"));
                            layer.classList.add("block");
                        }
                        card.forEach(btn=>btn.classList.remove("active"));
                        this.classList.add("active");
                    });
                });
                /*mostrar activo primera capa primer botón*/
                let allCards  =  document.querySelector(".mobile_prices-card").querySelectorAll(".layer-card"),
                    firstCard =  document.getElementById("layer-0"),
                    firstBtn  =  document.getElementById("btn--0"),
                    showFirst = function showFirst() {
                    if (window.innerWidth <= 580) {
                        firstCard.classList.add("block");
                        firstBtn.classList.add("active");
                    }
                }
                showFirst();
                window.addEventListener('resize' , () => {
                    allCards.forEach(hide=>hide.classList.remove("block"));
                    card.forEach(btn=>btn.classList.remove("active"));
                    firstCard.classList.add("block");
                    firstBtn.classList.add("active");
                });
            }
            /*tema--seguropatinete*/
            if (document.querySelector('.tema--seguropatinete')) {
                let cardActivePatinete = document.querySelector(".tema--seguropatinete").querySelectorAll(".index--0 .card");
                cardActivePatinete.forEach(element => {
                    element.classList.add("active");
                });
                if (window.innerWidth >= 580) {
                    let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                    [].forEach.call(card, function(el) {
                        el.addEventListener("mouseover", function() {
                            card.forEach(hide=>hide.classList.remove("active"));
                        }, false);
                    });
                    card.forEach(el=> {
                        el.addEventListener("mouseover", function() {
                            el.classList.remove("active");
                        }, false);
                    });
                }
                /*mostrar capas*/
                let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                card.forEach(element => {
                    element.addEventListener("click", function(){
                        let dataUrl = element.dataset.url;
                        let layer = document.querySelector(dataUrl);
                        let hideLayer = document.querySelectorAll(".layer-card");
                        if (this.classList.contains("block")){
                            this.classList.remove("block");
                        } else{
                            hideLayer.forEach(hide=>hide.classList.remove("block"));
                            layer.classList.add("block");
                        }
                        card.forEach(btn=>btn.classList.remove("active"));
                        this.classList.add("active");
                    });
                });
                /*mostrar activo primera capa primer botón*/
                let allCards  =  document.querySelector(".mobile_prices-card").querySelectorAll(".layer-card"),
                    firstCard =  document.getElementById("layer-0"),
                    firstBtn  =  document.getElementById("btn--0"),
                    showFirst = function showFirst() {
                    if (window.innerWidth <= 580) {
                        firstCard.classList.add("block");
                        firstBtn.classList.add("active");
                    }
                }
                showFirst();
                window.addEventListener('resize' , () => {
                    allCards.forEach(hide=>hide.classList.remove("block"));
                    card.forEach(btn=>btn.classList.remove("active"));
                    firstCard.classList.add("block");
                    firstBtn.classList.add("active");
                });
            }
            /*tema--seguromultidispositivo*/
            if (document.querySelector('.tema--seguromultidispositivo')) {
                let cardActivePatinete = document.querySelector(".tema--seguromultidispositivo").querySelectorAll(".index--1 .card");
                cardActivePatinete.forEach(element => {
                    element.classList.add("active");
                });
                if (window.innerWidth >= 580) {
                    let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                    [].forEach.call(card, function(el) {
                        el.addEventListener("mouseover", function() {
                            card.forEach(hide=>hide.classList.remove("active"));
                        }, false);
                    });
                    card.forEach(el=> {
                        el.addEventListener("mouseover", function() {
                            el.classList.remove("active");
                        }, false);
                    });
                }
                /*mostrar capas*/
                let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                card.forEach(element => {
                    element.addEventListener("click", function(){
                        let dataUrl = element.dataset.url;
                        let layer = document.querySelector(dataUrl);
                        let hideLayer = document.querySelectorAll(".layer-card");

                        if (this.classList.contains("block")){
                            this.classList.remove("block");
                        } else{
                            hideLayer.forEach(hide=>hide.classList.remove("block"));
                            layer.classList.add("block");
                        }
                        card.forEach(btn=>btn.classList.remove("active"));
                        this.classList.add("active");
                    });
                });
                /*mostrar activo primera capa primer botón*/
                let allCards  =  document.querySelector(".mobile_prices-card").querySelectorAll(".layer-card"),
                    firstCard =  document.getElementById("layer-0"),
                    firstBtn  =  document.getElementById("btn--0"),
                    showFirst = function showFirst() {
                    if (window.innerWidth <= 580) {
                        firstCard.classList.add("block");
                        firstBtn.classList.add("active");
                    }
                }
                showFirst();
                window.addEventListener('resize' , () => {
                    allCards.forEach(hide=>hide.classList.remove("block"));
                    card.forEach(btn=>btn.classList.remove("active"));
                    firstCard.classList.add("block");
                    firstBtn.classList.add("active");
                });
            }
            /*tema--seguroportatil*/
            if (document.querySelector('.tema--seguroportatil')) {
                let cardActivePortatil = document.querySelector(".tema--seguroportatil").querySelectorAll(".index--1 .card");
                cardActivePortatil.forEach(element => {
                    element.classList.add("active");
                });
                if (window.innerWidth >= 580) {
                    let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                    [].forEach.call(card, function(el) {
                        el.addEventListener("mouseover", function() {
                            card.forEach(hide=>hide.classList.remove("active"));
                        }, false);
                    });
                    card.forEach(el=> {
                        el.addEventListener("mouseover", function() {
                            el.classList.remove("active");
                        }, false);

                    });
                }
                /*mostrar capas*/
                let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                card.forEach(element => {
                    element.addEventListener("click", function(){
                        let dataUrl = element.dataset.url;
                        let layer = document.querySelector(dataUrl);
                        let hideLayer = document.querySelectorAll(".layer-card");

                        if (this.classList.contains("block")){
                            this.classList.remove("block");
                        } else{
                            hideLayer.forEach(hide=>hide.classList.remove("block"));
                            layer.classList.add("block");
                        }
                        card.forEach(btn=>btn.classList.remove("active"));
                        this.classList.add("active");
                    });
                });
                /*mostrar activo primera capa primer botón*/
                let allCards  =  document.querySelector(".mobile_prices-card").querySelectorAll(".layer-card"),
                    firstCard =  document.getElementById("layer-1"),
                    firstBtn  =  document.getElementById("btn--1"),
                    showFirst = function showFirst() {
                    if (window.innerWidth <= 580) {
                        firstCard.classList.add("block");
                        firstBtn.classList.add("active");
                    }
                }
                showFirst();
                window.addEventListener('resize' , () => {
                    allCards.forEach(hide=>hide.classList.remove("block"));
                    card.forEach(btn=>btn.classList.remove("active"));
                    firstCard.classList.add("block");
                    firstBtn.classList.add("active");
                });
            }
             /*tema--extensiondegarantia*/
            if (document.querySelector('.tema--extensiondegarantia')) {
                let cardActivePortatil = document.querySelector(".tema--extensiondegarantia").querySelectorAll(".index--0 .card");
                cardActivePortatil.forEach(element => {
                    element.classList.add("active");
                });
                if (window.innerWidth >= 580) {
                    let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                    [].forEach.call(card, function(el) {
                        el.addEventListener("mouseover", function() {
                            card.forEach(hide=>hide.classList.remove("active"));
                        }, false);
                    });
                    card.forEach(el=> {
                        el.addEventListener("mouseover", function() {
                            el.classList.remove("active");
                        }, false);
                    });
                }
                /*mostrar capas*/
                let card =  document.querySelector(".wrap_prices-card").querySelectorAll(".card");
                card.forEach(element => {
                    element.addEventListener("click", function(){
                        let dataUrl = element.dataset.url;
                        let layer = document.querySelector(dataUrl);
                        let hideLayer = document.querySelectorAll(".layer-card");

                        if (this.classList.contains("block")){
                            this.classList.remove("block");
                        } else{
                            hideLayer.forEach(hide=>hide.classList.remove("block"));
                            layer.classList.add("block");
                        }
                        card.forEach(btn=>btn.classList.remove("active"));
                        this.classList.add("active");
                    });
                });
                /*mostrar activo primera capa primer botón*/
                let allCards  =  document.querySelector(".mobile_prices-card").querySelectorAll(".layer-card"),
                    firstCard =  document.getElementById("layer-0"),
                    firstBtn  =  document.getElementById("btn--0"),
                    showFirst = function showFirst() {
                    if (window.innerWidth <= 580) {
                        firstCard.classList.add("block");
                        firstBtn.classList.add("active");
                    }
                }
                showFirst();
                window.addEventListener('resize' , () => {
                    allCards.forEach(hide=>hide.classList.remove("block"));
                    card.forEach(btn=>btn.classList.remove("active"));
                    firstCard.classList.add("block");
                    firstBtn.classList.add("active");
                });
            }
        },
        showBottomInfo(){
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                case landing.seguroportatil.name:
                case landing.extensiondegarantia.name:
                    return true
                default:
                    return false
            }
        },
        nameText(coverage){
            if(coverage.value_can_be_entered){
                return coverage.name + '$' + this.sliderValue
            }else{
                return coverage.name
            }
        },
        multiDeviceText(){
            /*var covered_mobiles = 0
            if(this.sliderValue < 700) {
                covered_mobiles = 1
            }else{
                covered_mobiles = 2
            }*/
            return this.$tc('calculatePriceComponent.insuranceBoxes.covered_mobiles') + this.$t("calculatePriceComponent.insuranceBoxes.all_devices_covered")
        },
        pushGTMEcommerceData() {
            const insurancesArray = []

            this.insurances.forEach((insurance, index) => {
                    var object = {
                        'name': this.currentLanding,     // Nombre del producto.
                        'id': this.getProductId(), // ID del producto
                        'price': this.insurancesPricesIndexed[this.getProductId()], // Precio del producto
                        'category': insurance.name, // Tipo de producto
                        'list': 'Home', // Lista en la que ha aparecido los productos
                        'brand': insurance.name, // Tipo del seguro
                        'position': index
                    }

                    insurancesArray.push(object)
            });

            

            this.pushGTMProducts('impression', insurancesArray)
        },
        pushGTMPriceData() {

            const insurancesArray = []

            this.insurances.forEach(() => {
                    var object = {
                        'id': this.getProductId(), // ID del producto
                        'price': this.insurancesPricesIndexed[this.getProductId()], // Precio del producto
                    }

                    insurancesArray.push(object)
            });
            
            this.pushGTMPrices('price_changed', this.sliderValue, insurancesArray);
        },

        pushGTMEcommerceClikData(insurance, index) {
            const insuranceObj = {
                'name': this.currentLanding,     // Nombre del producto.
                'id': this.getProductId(), // ID del producto
                'price': this.insurancesPricesIndexed[this.getProductId()], // Precio del producto
                'category': insurance.name, // Tipo de producto
                'brand': insurance.name, // Tipo del seguro
                'position': index
            }
            this.pushGTMInsuranceClick('productClick', this.getOrderLink(insurance), insuranceObj)
        },
        getInsurancesByLanding(landing){
            var insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === landing})[0]
            return insurancesFiltered.insurances
        }
    },
    mounted() {
        this.calculate()
        this.layers()
        this.pushGTMEcommerceData()
        this.getLowerPriceMultidevice()
    }
}

</script>

<style lang="scss" scoped>

.conditions-box{
    font-size: 0.6rem;
    color: #212529;
    
    cursor: pointer;
}

.p-conditions{
    line-height: 5px;
    text-decoration: underline;
}

.prices{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


}

.wrap_prices-card {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
}

.prices .row{
    width: 100%;
    height: auto;
}

.pricing {
    margin-top: 75px;
}

.pricingHeader {
    padding: 7px;
    text-align: center;
}

.pricing .card {
    background-color: $gray-color-light;
    border: none;
    min-height: 300px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px -6px 8px rgba(62, 86, 155, 0.04);
}

.card-title {
    font-size: 1.25rem;
    color: $red-color;
}

.pricing .card-subtitle {
    font-size: 1.4rem;

}

.pricing .card-subtitle>span {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 400;
}

.pricing .card-body>p {
    font-size: 16px;
    text-align: center;
}

.card-body {
    padding: 1rem;
}

.card-body_text{
    display: block;
    max-width: 260px;
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
}

.disabled {
    color: $blue-color-light;
    opacity: 0.5;
}

.pricing .card-body>.list-unstyled {
    line-height: 25px;
}

.pricing .card-body>.list-unstyled li svg {
    margin-right: 5px;
    width: 20px;
}

.pricing .card-body>.list-unstyled>li>.fas {
    width: 20px;
}

.pricing .card-body>nav>ul>li span {
    display: inline;
}

//extensiondegarantia//
.tema--extensiondegarantia .pricing .card{
    background: $white-color;
    box-shadow: 4px 6px 8px rgba(62, 86, 155, 0.20);
}
.tema--extensiondegarantia .pricing .card .card-title{
   color: $white-color;
}
.tema--extensiondegarantia .pricing .card .pricingHeader{
    background: $red-background-hover;
    color: $white-color;
}

//seguromultidispositivo//
.tema--seguromultidispositivo .pricing .card{
    background: $white-color;
    box-shadow: 4px 6px 8px rgba(62, 86, 155, 0.20);
}
.tema--seguromultidispositivo .pricing .card .card-title{
   color: $white-color;
}
.tema--seguromultidispositivo .pricing .card .pricingHeader{
    background: $red-background-hover;
    color: $white-color;
}
//end//

//hover card//

@media(min-width: 200px){
    //hover card//
    .pricing .card:hover,
    .pricing .card.active,
    .index--1.active {
        background: $white-color;
        box-shadow: 4px 6px 8px rgba(62, 86, 155, 0.20);
    }
    .pricing .card:hover .card-title,
    .pricing .card.active .card-title,
    .index--1.active .card-title{
        color: $white-color;
    }
    .pricing .card:hover .pricingHeader,
    .pricing .card.active .pricingHeader {
        background: $red-background-hover;
        color: $white-color;
    }
    .pricing .card:hover .btn-outline-danger,
    .pricing .card.active .btn-outline-danger,
    .index--1.active .btn-outline-danger{
    background: $red-background-hover;
    color: $white-color;
    }
}

.card {
    padding: 0;
    border-radius: .25rem;
    transition: all .2s cubic-bezier(.37,.01,0,.98);
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
}

.card:hover {
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
}

.btn {
    transition: 0s!important;
}

.btn-outline-danger:hover {
    text-transform: uppercase;
    font-weight: 600;
    background: $red-hover !important;
    border-color: #2d0c0e;
}

.big {
    font-size: 2rem;
}

.box {
    margin: 0 auto;
    background: $white-color;
    box-shadow: 0px 13px 18px rgba(73, 83, 113, 0.19);
    border-radius: 10px;
    padding: 15px;
    height: auto;
}
.box a{
    color: $blue-color;
    text-decoration: none;
}
.box a:hover{
    color: $blue-color-hover;
    text-decoration: none;
}
.wrap_prices-card .prices-card .container .row .pricingHeader  .card-subtitle{
    font-weight: bold;
    margin-top: 5px;
}
.tema--seguromovil .prices-card{
    min-width: 33.33%;
}
@media (max-width: 1200px){
    .wrap_prices-card {
        display: flex;
        flex-wrap: wrap;

    }

}
@media (max-width: 992px){
    .prices .prices-card {
        min-width: 80%;
    }
}

///mobile///

.mobile_prices-card .layer-card{
    width: 100%;
    box-shadow: 0px 13px 18px rgba(73, 83, 113, 0.19);
    border-radius: 0 0 5px 5px;
    padding: 30px 10px;
    background: $white-color;
}

.mobile_prices-card .layer-card{
    display: none;
}


@media only screen and (max-width: 768px) {
    .conditions-box{
        font-size: 0.6rem;
        color: #212529;
        cursor: pointer;
    }

    .p-conditions{
        line-height: 5px;
        text-decoration: underline;
    }

    .mobile_prices-card .mobile-card a{
        font-size: 0.6rem;
        color: #212529;
        cursor: pointer;
        margin: 0;
        background: none;
    }

    .pricing {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .conditions-box{
        font-size: 0.6rem;
        color: #212529;
        cursor: pointer;
    }

    .p-conditions{
        line-height: 5px;
        text-decoration: underline;
    }

    .mobile_prices-card .mobile-card a{
        font-size: 0.6rem;
        color: #212529;
        cursor: pointer;
        margin: 0;
        background: none;
    }
}
</style>

